exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-js": () => import("./../../../src/pages/product.js" /* webpackChunkName: "component---src-pages-product-js" */),
  "component---src-templates-categories-js": () => import("./../../../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-categories-js": () => import("./../../../src/templates/productCategories.js" /* webpackChunkName: "component---src-templates-product-categories-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/productPage.js" /* webpackChunkName: "component---src-templates-product-page-js" */)
}

